
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { dispatchGetApps } from '@/store/admin/actions';
import { readApps } from '@/store/admin/getters';

@Component
export default class AdminApplications extends Vue {
  private headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Abbreviation',
      sortable: true,
      value: 'abbr',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'id',
      align: 'center',
    },
  ];

  private tableHeight = 0;

  private async mounted() {
    await dispatchGetApps(this.$store);
  }

  private get apps() {
    return readApps(this.$store);
  }

  private handleResize() {
    this.tableHeight =
      window.innerHeight - /* header */ 64 - /* title */ 64 - /* footer*/ 48;
  }
}
